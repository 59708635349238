<template>
  <div class="info__inner">
    <div class="info__header">
      <div class="info__nav">
        <div v-if="isArchived"  class="info__archive">
          <i class="ri-archive-line link__sidebar_icon"></i>
        </div>
        <div class="nav__link" @click="block = 'main'" :class="{'nav__link--active': block === 'main'}">
          Основна
        </div>
        <div class="nav__link" @click="block = 'contacts'" :class="{'nav__link--active': block === 'contacts'}">
          Контакти
        </div>
      </div>
      <div class="info__buttons">
        <div class="info__buttons-left">
          <div class="info__icon" :class="{'is-red': isArchived}">
            <i class="ri-shopping-bag-fill"></i>
            <i v-if="renter.lookingFor === 'apartment'" class="ri-building-4-fill"></i>
            <i v-if="renter.lookingFor === 'house'" class="ri-home-5-fill"></i>
            <i v-if="renter.lookingFor === 'commerce'" class="ri-store-fill"></i>
          </div>
        </div>
        <div class="info__buttons-right">
          <div v-if="!isArchived" class="info__button button__icon button__icon--border" @click="favorites">
            <i class="i-bookmark" :class="{'i-bookmark--blue': renter.isSaved}"></i>
          </div>
          <div
            v-if="!isArchived && (user.role === 4 || user.id === renter.responsibleID || !renter.responsibleID) "
            class="card__button button__icon button__icon--border"
            @click.stop.prevent
          >
            <Multiselect
              class="mSelect-dropdown"
              :options="list"
              :searchable="false"
              :show-labels="false"
              :reset-after="true"
              :value="item"
              placeholder=""
              open-direction="bottom"
              @select="actions"
            />
          </div>
          <div
            v-if="isArchived && (user.role === 4 || user.id === renter.responsibleID || !renter.responsibleID)"
            class="card__button button__icon button__icon--border"
            @click.stop.prevent
          >
            <Multiselect
              class="mSelect-dropdown"
              :options="listArchive"
              :searchable="false"
              :show-labels="false"
              :reset-after="true"
              :value="item"
              placeholder=""
              open-direction="bottom"
              @select="actions"
            />
          </div>
          <div class="button__icon button--onmobile" @click="$modal.hide('InfoRenter')">
            <i class="ri-close-fill"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="info__content">
      <div class="info__main" v-if="block === 'main'">
        <div class="main__header">
          <div class="main__header-top">
            <div class="main__price card__price">$ {{ renter.maxPrice }}</div>
            <div class="main__indicators">
              <div :class="{
                'card__tag card__tag--green main__indicator': renter.relevance === localConstants.RENT_RELEVANCE.RELEVANT,
                'card__tag card__tag--yellow main__indicator': renter.relevance === localConstants.RENT_RELEVANCE.RENTED,
                }">
                <span class="card__span">{{ renter.relevanceName }}</span>
                <span v-if="renter.relevance === localConstants.RENT_RELEVANCE.RENTED"> до {{ renter.rentedTill | date }}</span>
              </div>
            </div>
          </div>
          <div class="main__header-middle">
            <!-- {{ renter.name }} -->
          </div>
          <div class="main__header-bottom">
            <div class="main__id">ID {{ renter.id }}</div>
            <div class="main__date"><i class="i-calendar"></i> {{ renter.createdAt | date }}</div>
            <Relation v-for="(rel, i) in renter.relations" type="info-tile" :relation="rel" :compact="true" :key="i"/>
            <div class="main__address">
              <i class="i-location"></i>
              <span>{{ settlements }}, </span>
              <span>{{ districtsName }}</span>
            </div>
          </div>
        </div>
        <div class="main__content">
          <div class="main__row main__row--dark">
            <div class="main__row-left">
              <i class="i-employees"></i>
              <div class="main__text">Автор</div>
            </div>
            <div class="main__row-right">
              <div class="main__row-letter">
                <div>{{ renter.authorName[0] }}</div>
              </div>
              <div class="main__text">{{ renter.authorName }}</div>
            </div>
          </div>
          <div class="main__row main__row--dark">
            <div class="main__row-left">
              <i class="i-employees"></i>
              <div class="main__text">Відповідальний агент</div>
            </div>
            <div class="main__row-right">
              <div class="main__row-letter">
                <div>
                  {{ renter.responsibleName[0] }}
                </div>
              </div>
              <div class="main__text">{{ renter.responsibleName }}</div>
            </div>
          </div>
          <div class="main__row">
            <div class="main__text">К-сть кімнат</div>
            <div class="main__text">{{ renter.noOfRooms }}</div>
          </div>
          <div class="main__row" v-if="renter.lookingFor !== 'commerce'">
            <div class="main__text">Вид</div>
            <div class="main__text">{{ material }}</div>
          </div>
          <div class="main__row">
            <div class="main__text" v-if="renter.lookingFor === 'commerce'">Призначення</div>
            <div class="main__text" v-else>Тип</div>
            <div class="main__text">{{ buildingType }}</div>
          </div>
          <div class="main__row" v-if="renter.lookingFor === 'apartment'">
            <div class="main__text">Поверх</div>
            <div class="main__text">{{ floor }}</div>
          </div>
          <div class="main__row" v-if="renter.lookingFor !== 'house'">
            <div class="main__text">Ремонт/Авт. опал.</div>
            <div class="main__text">
              <span class="card__span" v-if="renter.isRenovated">Є</span>
              <span class="card__span" v-else>Немає</span>
              <span class="card__span">/</span>
              <span class="card__span">{{ renter.autonomousHeatType }}</span>
            </div>
          </div>
          <div class="main__row" v-if="renter.lookingFor === 'house'">
            <div class="main__text">Ремонт/Гараж</div>
            <div class="main__text">
              <span class="card__span" v-if="renter.isRenovated">Є</span>
              <span class="card__span" v-else>Немає</span>
              <span class="card__span">/</span>
              <span class="card__span" v-if="renter.hasGarage">Є</span>
              <span class="card__span" v-else>Немає</span>
            </div>
          </div>
          <div class="main__row" v-if="renter.lookingFor === 'house'">
            <div class="main__text">Розташування</div>
            <div class="main__text">
              <span class="card__span">{{ renter.range }}</span>
            </div>
          </div>
          <div class="main__row" v-if="renter.lookingFor !== 'apartment'">
            <div class="main__text">Площа</div>
            <div class="main__text">
              <span class="card__span">{{ renter.squareLand }}</span>
              <span class="card__span" v-if="renter.squareTotal"> / {{ renter.squareTotal }}</span>
            </div>
          </div>
          <div class="main__row">
            <div class="main__text">Джерело</div>
            <div class="main__text">
              {{ renter.source | sourcePath(constants.RENT_BUYER_SOURCES) }} {{ (renter.seeker ? ' > ID ' + renter.seeker.id : '' ) }}
            </div>
          </div>
          <div class="main__row">
            <div class="main__text">Актуальність</div>
            <div class="main__text">
              {{ renter.relevanceName }}
              <span v-if="renter.relevance === localConstants.RENT_RELEVANCE.RELEVANT" style="white-space: pre"> до {{ renter.rentedTill | date }}</span>
            </div>
          </div>
          <div class="main__row">
            <div class="main__text">Останній дзвінок</div>
            <div class="main__text">
              <i class="i-calendar"></i>
              <div v-if="renter.lastCallAt">{{ moment(renter.lastCallAt).format('DD.MM.YYYY') }}</div>
              <div v-else>-</div>
            </div>
          </div>
          <div v-if="isArchived" class="main__row main__row--blue">
            <div class="main__text">Причина видалення</div>
            <div class="main__text">
              {{ archivedReason }}
            </div>
          </div>
          <div v-if="isArchived" class="main__row main__row--blue">
            <div class="main__text">Видалено до</div>
            <div class="main__text">
              <i class="i-calendar"></i>
              {{ renter.archivedTill | date }}
            </div>
          </div>
          <div v-if="isArchived" class="main__row main__row--blue">
            <div class="main__text">Дата видалення</div>
            <div class="main__text">
              <i class="i-calendar"></i>
              {{ renter.archivedAt | date }}
            </div>
          </div>
          <div class="main__row main__row--area">
            <div class="main__text">Опис</div>
            <div class="main__text">
              <textarea id="autoresizing" disabled>{{ renter.description }}</textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="info__contacts" v-if="block === 'contacts'">
        <button class="contacts__button button button--fill" @click="changeLastCall">Зберегти дзвінок</button>
        <div class="contacts__item" v-for="contact in renter.contacts" :key="contact.id">
          <div class="contacts__header">
            <div class="contacts__letter">{{ contact.name[0] }}</div>
            <div class="contacts__name">{{ contact.name }}</div>
            <div class="contacts__role">Роль: {{ contact.roles.map(x => x.name).join(' / ') }}</div>
            <div class="contacts__date">Дата народження: {{ contact.birthday }}</div>
            <div v-if="contact.company" class="contacts__company">
              Компанія: "{{ contact.company.name }}"
              <span v-if="contact.jobPosition">({{ contact.jobPosition.name }})</span>
            </div>
          </div>
          <div class="contacts__phones" v-for="phone in contact.phones" :key="phone.id">
            <div class="contacts__phone-wrapper">
              <a :href="'tel:' + phone.phone" @click.stop><i class="ri-phone-fill"></i></a>
              <div class="contacts__phone">{{ phone.phone }}</div>
              <div class="contacts__messengers">
                <button
                  class="button__circle"
                  :class="{'button__circle--blue': phone.isOnViber}"
                  @click="sendMessage({...phone, message: 'viber'})"
                >
                  <i class="i-viber"></i>
                </button>
                <i
                  class="i-telegram button__circle"
                  :class="{
                    'button__circle--blue': phone.isOnTelegram
                  }"
                ></i>
                <i
                  class="i-whatsapp button__circle"
                  :class="{
                    'button__circle--blue': phone.isOnWhatsapp
                  }"
                ></i>
                <i
                  class="i-messanger button__circle"
                  :class="{
                    'button__circle--blue': phone.isOnFacebook
                  }"
                ></i>
              </div>
            </div>
          </div>
          <div class="contacts__rel">
            <Relation v-for="(rel, i) in contact.relations" :key="i" :relation="rel" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Archive from '@/components/ModalArchive'
import Edit from '../Upload'
import moment from 'moment'
import { mapGetters } from 'vuex'
import Relation from '@/components/Relation'
import localConstants from '@/utils/localConstants'

export default {
  name: 'InfoRenter',
  props: ['renter'],
  components: {
    Multiselect,
    Relation,
  },
  data: () => ({
    moment,
    localConstants,
    block: 'main',
    list: ['Редагувати', 'В Архів'],
    listArchive: ['Відновити'],
    item: '',
    floor: null,
    material: null,
    buildingType: null,
    districtsName: null,
    archivedReason: null
  }),
  computed: {
    ...mapGetters(['constants', 'user']),
    isArchived() {
      return this.renter?.archivedAt != null
    }
  },
  async created() {
    this.districtsName = this.renter.districtID.map(d => d.name).join(', ') // any??
    this.settlements = this.renter.settlement.join(', ')
    if (this.renter.lookingFor === 'apartment') {
      this.floor = this.renter.floor.join(', ')
      this.material = this.renter.material
        .map(m => this.constants.APARTMENT_MATERIALS.find(mat => mat.id === m).name)
        .join(', ')
      this.buildingType = this.renter.buildingType
        .map(type => this.constants.APARTMENT_TYPES.find(t => t.id === type).name)
        .join(', ')
    } else if (this.renter.lookingFor === 'house') {
      this.material = this.renter.material.map(m => this.constants.HOUSE_MATERIALS.find(mat => mat.id === m).name).join(', ')
      this.buildingType = this.renter.buildingType
        .map(type => this.constants.HOUSE_TYPES.find(t => t.id === type).name)
        .join(', ')
    } else if (this.renter.lookingFor === 'commerce') {
      this.buildingType = this.renter.buildingType
        .map(type => this.constants.COMMERCE_BUILDING_TYPES.find(t => t.id === type).name)
        .join(', ')
    }
    if (this.user.role === 4) {
      this.list.push('Видалити')
    }
    if (this.isArchived) {
      this.archivedReason = this.constants.RENT_ARCHIVED_REASONS.find(r => r.id === this.renter.archivedReason).name
      this.soldBy = this.constants.SOLD_BYS.find(x => x.id == this.renter.soldBy)?.name
    }
    if (this.renter.contacts?.length > 0) {
      const relations = await this.$store.dispatch('contacts/fetchRelations', this.renter.contacts.map(x => x.id))
      for (const contact of this.renter.contacts) {
        contact.relations = relations.filter(x => x.id === contact.id)
      }
    }
  },
  mounted() {
    let textarea = document.querySelector('#autoresizing')
    function autoResize() {
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
      textarea.disable = 'true'
    }
    autoResize()
  },
  methods: {
    async favorites() {
      const id = this.renter.id
      const isSaved = this.renter.isSaved
      let path = ''
      switch (this.$route.meta.class) {
        case 'apartment':
          path = 'renters/apartments/favorites'
          break
        case 'house':
          path = 'renters/houses/favorites'
          break
        case 'commerce':
          path = 'renters/commerce/favorites'
          break
      }
      try {
        await this.$store.dispatch(path, {id, isSaved})
        this.renter.isSaved = !this.renter.isSaved
      } catch (err) {}
    },
    actions(option) {
      const id = this.renter.id
      const type = this.$route.meta.class
      if (option === 'Редагувати') {
        this.$modal.display(
          Edit,
          {info: this.renter, renter: this.$route.meta.class},
          {name: 'UploadRenter', clickToClose: false}
        )
        this.$modal.hide('InfoRenter')
      } else if (option === 'В Архів') {
        this.$modal.display(
          Archive,
          {info: this.renter, entity: 'renters', action: 'archive'},
          {transition: 'none', classes: 'modalArchive', width: '500px', height: '350px', name: 'ModalArchive'}
        )
      } else if (option === 'Видалити') {
        this.$store.dispatch('deleteArchiveRenters', {type, id})
      } else if (option === 'Відновити') {
        this.$store.dispatch('restoreArchiveRenters', {type, id})
      }
    },
    async changeLastCall() {
      const type = this.$route.meta.class
      let obgType = ''
      if (type === 'apartment') {
        obgType = 'apartments'
      } else if (type === 'house') {
        obgType = 'houses'
      } else if (type === 'commerce') {
        obgType = 'commerce'
      }
      try {
        await this.$store.dispatch(`renters/${obgType}/toggleLastCall`, this.renter.id)
        this.renter.lastCallAt = new Date()
        this.$modal.hide('InfoRenter')
      } catch (err) {
        this.$store.commit('setError', err)
      }
    },
    sendMessage(phone) {
      const number = phone.phone.slice(1, 4) + phone.phone.slice(6, 9) + phone.phone.slice(10, 14)
      switch (phone.message) {
        case 'viber':
          if (phone.isOnViber) document.location.href = `viber://chat?number=${number}`
          break
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/info.scss';
</style>
